import logo from './logo.svg';
import './App.css';
import Login from './Screens/Login';
import Redeemed from './Screens/Redeem';
import Scanner from './Screens/Scanner';
import SelectEvent from './Screens/SelectEvent';
import PrivateRoute from './Screens/PrivateRoute';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {

  


  return (
    <div className="App">
      <Router>
        <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              {/* <Route exact path="/" element={<PrivateRoute />}> */}
                {/* <Route path="/scan" element={<Scanner />} /> */}
                <Route path="/event" element={<SelectEvent />} />
                <Route path="/event/:id" element={<Scanner />} />
                <Route path="/redeem" element={<Redeemed />} />
                <Route path="/redeem/:id" element={<Redeemed />} />
              {/* </Route> */}
            </Routes>
      </Router>
    </div>
  );
}

export default App;
