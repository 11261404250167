import React from 'react';
import RedeemCard from './RedeemCard';
import {useEffect, useState} from "react";
import { API_URL } from '../constants';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Redeemed(props) {
    // Load data from props
    const location = useLocation();
    const redemptionData = location.state;
    const redemption_id = useParams()?.id;

    
    console.log(redemptionData)

    return (
        <div>
            <RedeemCard data={redemptionData} />
        </div>
    );
}

export default Redeemed;