import React from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { CardActionArea, CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { API_URL } from '../constants';
import { useEffect, useState } from 'react';


const useStyles = makeStyles({
    card: {
        maxWidth: 400,
        margin: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

const RedeemCard = ({ data }) => {
    const { id, guest_name, guest_email, guest_rut, reward_title, reward_description, is_redeemed, is_active, sent_at, redeemed_at } = data;
    const [isRedeemed, setIsRedeemed] = useState();
    const [redeemedAt, setRedeemedAt] = useState();


    // Definimos el efecto que se ejecutará cuando el valor de data cambie
  useEffect(() => {
    // Verificamos si data no es undefined
    if (data !== undefined) {
      setIsRedeemed(is_redeemed)
      setRedeemedAt(redeemed_at)
      // Aquí puedes colocar cualquier lógica adicional que desees ejecutar cuando data deje de ser undefined
    }
  }, [data]); // <-- data se agrega como dependencia

    //console.log(is_redeemed, redeemed_at)
    //console.log(isRedeemed, redeemedAt)

    const classes = useStyles();

    const handleRedeemClick = async () => {
        try {
            const response = await axios.patch(`${API_URL}/invites/redemptions/${id}/`, {
                is_redeemed: true,
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    },
                });

            setIsRedeemed(true);
            setRedeemedAt(response.data.redeemed_at);
            // Handle the response as needed
            console.log('Redemption successful:', response.data);

            // You might want to update state or perform other actions here
        } catch (error) {
            // Handle errors
            console.error('Error redeeming coupon:', error);
        }
    };

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content} style={{backgroundColor: isRedeemed?"#ff6961":"#77dd77"}}>
                <Typography variant="h6" className={classes.title}>
                    Detalles del Cupón
                </Typography>
                <Typography variant="body1">Nombre invitado/a: {guest_name}</Typography>
                <Typography variant="body1">Email: {guest_email}</Typography>
                <Typography variant="body1">RUT: {guest_rut}</Typography>
                <Typography variant="body1">Evento: {reward_title}</Typography>
                <Typography variant="body1">Descripción: {reward_description}</Typography>
                <Typography variant="body1">¿Canjeado?: {isRedeemed ? 'Sí' : 'No'}</Typography>
                <Typography variant="body1">Activo: {is_active ? 'Sí' : 'No'}</Typography>
                <Typography variant="body1">Enviado en: {sent_at}</Typography>
                {isRedeemed ? <Typography variant="body1">Canjeado en: {redeemedAt}</Typography> : <></>}
            </CardContent>
            <CardActions>
                {isRedeemed ? <></> : <Button size="small" color="primary" onClick={handleRedeemClick}>Canjear</Button> }
            </CardActions>
        </Card>
    );
};

export default RedeemCard;