import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, TextField } from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CodeInputModal = ({ isOpen, onClose, onSubmit }) => {
  const [code, setCode] = useState('');

  const handleInputChange = (event) => {
    setCode(event.target.value);
  };

  const handleSubmit = () => {
    // Validate code if needed
    if (code.trim() !== '') {
      onSubmit(code);
      onClose();
    }
  };

  

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <TextField
          label="Código de invitación"
          value={code}
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
          margin="normal"
        />
        <Button color="primary" onClick={handleSubmit}>
          Enviar
        </Button>
      </Box>
    </Modal>
  );
};

export default CodeInputModal;