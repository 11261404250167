import React, {useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { API_URL } from '../constants';
import axios from 'axios';

import { decodeToken } from 'react-jwt';


export const Login = () => {
    const [email,setEmail] =  useState("")
    const [password,setPassword] = useState("")
    const [showPassword, setShowPassword]= useState(false)
    
    const submit = async e => {
      e.preventDefault();

      const user = {
        email: email,
        password: password
      };
      const {data} = await axios.post(
        `${API_URL}/token/`,
        user,
        {headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: false})
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });

        const {full_name, logo} = decodeToken(data.access);

        localStorage.clear();
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        localStorage.setItem('full_name', full_name);
        localStorage.setItem('logo', logo);

        
        axios.defaults.headers.common['Authorization'] = 
                                         `Bearer ${data['access']}`;
        console.log(axios.defaults.headers.common)


        window.location.href = '/event/';
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    

    return ( 
    <div className='LoginLayout'>
        <div className='GreyBlock' />
        <div className='MainHeader'>Cranberry Invites</div>
        <form onSubmit={submit} className='LoginForm'>
        <TextField
          required
          id="outlined-required"
          label="Email"
          placeholder='example@email.com'
          className='TextForm InsideForm'
          onChange={(e) => setEmail(e.target.value)}
        />
        
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        <Button type="submit" variant="contained" className='ButtonForm InsideForm' >Log in</Button>
        <div className='Link'> Olvide mi contrasena </div>
        </form>


    </div>
    );
}

export default Login;