import React from 'react';
import {useEffect, useState} from "react";
import EventCard from './EventCard';
import { API_URL } from '../constants';
import axios from 'axios';


const SelectEvent = () => {
    const [events, setEvents] = useState([]);


    useEffect(() => {
        if (localStorage.getItem('access') === null) {
            window.location.href = '/login'
        }
        else {
            (async () => {
                try {
                    console.log(axios.defaults.headers.common)
                    const { data } = await axios.get(
                        `${API_URL}/invites/rewards/`,
                        {headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('access'),
                        },
                        }
                    );
                    setEvents(data.results);
                } catch (e) {
                    console.log('not auth')
                }
            })()
        };
    }, []);

    return (
        <div>
            <div className='header'>
                <img src={localStorage.getItem('logo')} alt='logo' height={200} />
                <h1>Selecciona un evento</h1>
            </div>
            <div>
            {events.map((event) => (
                <EventCard data={event} />
            ))}
            </div>
        </div>
    );

}

export default SelectEvent;