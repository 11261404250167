import React from 'react';
//import {useEffect, useState} from "react";
//import EventCard from './EventCard';
import { API_URL } from '../constants';
import axios from 'axios';
import Html5QrcodePlugin from './Html5QRcodePlugin';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ErrorModal from './ErrorModal';
import CodeInputModal from './CodeInputModal';



const Scanner = () => {
    const reward_id = useParams()?.id;
    const navigate = useNavigate();

    const [openModal, setOpenModal] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState("");

    const [openInputModal, setOpenInputModal] = React.useState(false);
    const handleOpenInputModal = () => {
        setOpenInputModal(true);
    };

    const handleCloseInputeModal = () => {
        setOpenInputModal(false);
    };

    const handleCodeSubmit = (code) => {
        // Here, you can make the API call to /api/code with the 'code' parameter
        // For simplicity, let's just log the code for now
        console.log('Submitting manual code:', code);
        onNewScanResult(`CBC:${code}`, null);
    };


    // const dispatchQRCodeScanned = (decodedText) => {
    async function dispatchQRCodeScanned(decodedText) {
        // Check if the QR code is a CBC code
        if (/^CBC:[A-Z2-7]+/.test(decodedText)) {
            console.log(`Scanned QR code CBC: ${decodedText}`);

            // Make GET request to the API with the code on the parameters of the url
            const code = decodedText.split(':')[1];

            try {
                const res = await axios.get(
                    `${API_URL}/invites/rewards/${reward_id}/code/${code}/`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('access'),
                        },
                    }
                )
                const { data, status } = res
                console.log('Succes code');
                return { message: 'Success', data: data, status: status };
            }

            catch (e) {
                console.log(e);
                const message = `Error al obtener datos. Error: ${e?.response?.status || e}`;
                console.log(message)
                return { message: message, data: null, status: null };
            };
        }

        else {

            // Check if the QR code is a ID document
            try {
                const url = new URL(decodedText);
                if (url.host !== 'portal.sidiv.registrocivil.cl') {
                    return { message: `QR no corresponde a cédula: ${url}`, data: null, status: null };
                }
                const run = url.searchParams.get('RUN');
                if (run === null) {
                    return { message: 'No se puede obtener el RUT', data: null, status: null };
                }

                try {
                    const res = await axios.get(
                        `${API_URL}/invites/rewards/${reward_id}/rut/${run}/`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                            },
                        }
                    )
                    console.log(res);
                    const { data, status } = res;
                    return { message: 'Success', data: data, status: status };
                }
                catch (e) {
                    console.log(e);
                    const message = `Error al obtener datos. Error: ${e?.response?.status || e}`;
                    console.log(message)
                    return { message: message, data: null, status: null };
                };


            }
            catch (e) {
                // Not a valid URL
                const message = `Not a valid url nor code: ${decodedText}`;
                console.log(message);
                return { message: message, data: null, status: null };
            }
        }

    };

    const onNewScanResult = (decodedText, decodedResult) => {
        console.log("onNewScan Function")
        dispatchQRCodeScanned(decodedText).then((res) => {
            console.log(res);
            if (res == null) {
                console.log('Un error con la comunicación ha ocurrido');
                setModalMessage('Error de comunicación');
                setOpenModal(true);
                return;
            }
            const { data, status, message } = res;
            if (status != 200) {
                console.log('Error scanning QR code');
                setModalMessage(message);
                setOpenModal(true);
                return;
            }
            navigate(`/redeem/${data.id}`, { state: data });
            return;
        }
        ).catch((e) => console.log(e));

    };

    return (
        <section className='App-section'>
            <div classname='App-section-title'>Escanee aquí</div>
            <Html5QrcodePlugin
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
            />

            <ErrorModal open={openModal} setOpen={setOpenModal} message={modalMessage} />

            <div>
                <button onClick={handleOpenInputModal}>Ingreso manual</button>
                <CodeInputModal
                    isOpen={openInputModal}
                    onClose={handleCloseInputeModal}
                    onSubmit={handleCodeSubmit}
                />
            </div>
        </section>
    );

}

export default Scanner;