import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

const EventCard = ({ data }) => {
    const { id, title, description, current, total } = data;
  
    return (
      <Card>
        <CardActionArea component={RouterLink} to={`/event/${id}`}>
            <CardContent>
            <Typography variant="h5" component="div">
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {current} de {total} asistentes.
            </Typography>
            </CardContent>
        </CardActionArea>
      </Card>
    );
  };

export default EventCard;